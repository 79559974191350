// components/Logo.js
import React from "react";
import { createIcon } from "@chakra-ui/icons";

 const Logo = createIcon({
  displayName: "Logo",
  viewBox: "0 0 282 230",
  path: (
    <svg  fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_232_742"
        style={{maskType: "alpha"}}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
      >
        <path
          d="M282 69.3233C282 28.4872 246.561 1 206.058 1C179.388 1 146.762 25.0883 142.849 59.688C136.761 25.0883 109.945 1 83.2749 1C56.605 1 7.17765 4.94186 1.0884 69.3233C0.715591 73.265 1.08839 96.9154 11.0898 120.566C43.0265 196.087 142.849 234 142.849 234C142.849 234 239.34 191.004 271.129 120.566C277.652 106.113 282 78.6548 282 69.3233Z"
          fill="white"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.6125 133.267H68.4897V128.449C68.4897 128.449 60.2276 124.121 60.2276 116.624C60.2276 110.054 64.5761 102.609 73.2731 102.609C81.97 102.609 85.8837 110.492 85.8837 116.624C85.8837 122.755 78.0564 128.449 78.0564 128.449V133.267H142.414V68.0092V198.086H138.5C138.5 198.086 135.021 190.203 125.89 190.203C119.044 190.203 113.469 196.014 113.714 202.904C113.95 209.553 118.497 214.729 125.89 214.729C133.282 214.729 138.5 208.16 138.5 208.16H142.414V233.124"
          stroke="black"
        />
        <path
          d="M146.762 68.4471C146.762 68.4471 153.285 75.4546 160.243 75.4546C167.448 75.4546 173.288 69.5721 173.288 62.3155C173.288 55.059 167.448 49.1765 160.243 49.1765C151.546 49.1764 146.762 59.6877 146.762 59.6877"
          stroke="black"
        />
        <path
          d="M147.197 68.4471C145.159 68.4471 141.979 68.4471 141.979 68.4471"
          stroke="black"
        />
        <path
          d="M147.197 59.6884C145.499 59.6884 142.849 59.6884 142.849 59.6884"
          stroke="black"
        />
        <path
          d="M265.041 133.267C227.47 133.267 217.642 133.267 217.642 133.267V138.523C217.642 138.523 225.904 142.902 225.904 150.348C225.904 157.355 219.953 163.049 212.859 163.049C205.032 163.049 199.814 157.793 199.814 150.348C199.814 142.902 208.076 138.523 208.076 138.523V133.267L141.109 133.267"
          stroke="black"
        />
      </mask>
      <g mask="url(#mask0_232_742)">
        <g clip-path="url(#clip0_232_742)">
          <path
            d="M-2.50635 -2.53149H143.887V135.939H-2.50635V-2.53149Z"
            fill="#D21E1F"
          />
          <path
            d="M143.887 -2.53149H288.517V135.939H143.887V-2.53149Z"
            fill="#F8C00F"
          />
          <path
            d="M143.887 135.939H288.517V241.141H143.887V135.939Z"
            fill="#4B510B"
          />
          <path
            d="M-2.50635 135.336H144.57V247.553H-2.50635V135.336Z"
            fill="#A8CE27"
          />
          <ellipse
            cx="75.7258"
            cy="127.32"
            rx="15.6464"
            ry="14.428"
            fill="#A8CE27"
          />
          <path
            d="M141.711 196.254C148.773 196.254 154.498 202.091 154.498 209.291C154.498 216.492 148.773 222.329 141.711 222.329C134.649 222.329 128.924 216.492 128.924 209.291C128.924 202.091 134.649 196.254 141.711 196.254Z"
            fill="#4B510B"
          />
          <path
            d="M204.026 138.755C204.026 131.554 210.146 125.717 217.696 125.717C225.245 125.717 231.365 131.554 231.365 138.755C231.365 145.955 225.245 151.793 217.696 151.793C210.146 151.793 204.026 145.955 204.026 138.755Z"
            fill="#F8C00F"
          />
          <rect
            x="67.1628"
            y="128.746"
            width="9.70078"
            height="9.89076"
            fill="#A8CE27"
          />
          <path
            d="M134.187 215.065V205.174H143.887V215.065H134.187Z"
            fill="#4B510B"
          />
          <path
            d="M221.602 145.839H211.901V135.948H221.602V145.839Z"
            fill="#F8C00F"
          />
          <path
            d="M148.851 74.8435C141.302 74.8435 135.182 69.0063 135.182 61.8057C135.182 54.6051 141.302 48.7679 148.851 48.7679C156.401 48.7679 162.521 54.6051 162.521 61.8057C162.521 69.0063 156.401 74.8435 148.851 74.8435Z"
            fill="#D21E1F"
          />
          <path
            d="M153.588 57.7122V66.7038H143.887V57.7122H153.588Z"
            fill="#D21E1F"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_232_742">
          <rect
            width="291.023"
            height="243.672"
            fill="white"
            transform="translate(-2.50635 -2.53149)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
});

export default Logo;
